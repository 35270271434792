import React from "react"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import resume from '../files/chance-murphy-resume.pdf'

const CTA = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  return (
    <section class="resume-cta">
      <div class="sw">
        <div class="content">
          <h3>Interested in working together?</h3>
          <div className="buttons">
            <a href={resume} target="_blank" rel="noreferrer" class="cta">View Resume</a>
            <a href="mailto:chancem@umich.edu" target="_blank" rel="noreferrer" class="cta">Contact</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA