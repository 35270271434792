import React from "react"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import header from '../../images/projects/asc-header.png'
import pwireframe from '../../images/projects/adrian-steel/products-wireframe.png'
import pcwireframe from '../../images/projects/adrian-steel/product-cat-wireframe.png'
import ppwireframe from '../../images/projects/adrian-steel/product-wireframe.png'

// Component Imports
import Layout from "../../layout"
import ResumeCTA from "../../components/resume-cta"

const ASC = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  return (
    <Layout>


      <section class="project-hero" style={{ backgroundImage: `url(${header})` }}>
      </section>


      <section class="project-intro">
        <div class="sw">
          <div class="content">
            <h1>Adrian Steel Website Re-Design</h1>
            <div class="body">
              <div class="left">
                <div class="item">
                  <h3>Company</h3>
                  <ul>
                    <li>Adrian Steel | <a href="https://www.adriansteel.com" target="_blank" rel="noreferrer">adriansteel.com</a></li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Date</h3>
                  <ul>
                    <li>June 2020 - May 2021</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Process</h3>
                  <ul>
                    <li>Agile</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Role</h3>
                  <ul>
                    <li>UX Designer</li>
                    <li>UX Researcher</li>
                    <li>Front End Developer</li>
                    <li>Project Manager</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Responsibilities</h3>
                  <ul>
                    <li>Lo-Fidelity Wireframing</li>
                    <li>Prototyping</li>
                    <li>Front End Development</li>
                    <li>Quality Assurance Testing</li>
                    <li>Communicate client feedback and expectations to cross-functional teams.</li>
                  </ul>
                </div>
                <div class="item item--last">
                  <h3>Technologies Used</h3>
                  <ul>
                    <li>Protoshare (Wireframing Tool)</li>
                    <li>Adobe Photoshop</li>
                    <li>HTML &amp; CSS/SCSS</li>
                    <li>PHP</li>
                    <li>Prepros</li>
                    <li>Github / Git</li>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div class="item">
                  <h2>Overview</h2>
                  <p>Adrian Steel is a nationwide leader in creating products and accessories that allow tradesmen to upfit their work vans. They take a unique and personal approach to keep their customers safe and productive in their work vehicles while on the go or at the jobsite. Their personal approach allows them to learn the ins and outs of their clients needs and daily routines to offer them a tailor made solution perfect for them.</p>
                </div>
                <div class="item">
                  <h2>Problem</h2>
                  <p>Adrian Steel’s existing website had become outdated over the years, featuring some large usability concerns, most prominently a lack of any form of mobile layout or responsive design. After years of planning, Adrian Steel approached Artonic in June of 2020 with the desire to fully move forward with redesigning their existing website from the ground up. </p>
                </div>
                <div class="item">
                  <h2>Solution</h2>
                  <p>After years of planning, Adrian Steel approached Artonic in June of 2020 with the desire to fully move forward with redesigning their existing website from the ground up. Over the next 11 months, myself and the team at Artonic would work to complete the website project and launch a website that both parties could be proud of. Our process had already been kicked off years before the technical start date of the project, so with a headstart we worked to perfect the layout and user experience of the new website through an iterative design process starting with low fidelity wireframes and concluding with the development of the site. </p>
                </div>
                <div class="item item--goals">
                  <h2>My Tasks</h2>
                  <ol class="list">
                    <li><strong>1.</strong> Create low fidelity wireframes for pages in the Product, Vehicle, Trades, and Company sections of the website.</li>
                    <li><strong>2.</strong> Create low fidelity wireframes for additional pages added during the development phase such as Truck Equipment, Interior Packages, Legal.</li>
                    <li><strong>3.</strong> After launch, wireframe additional product pages to be nested under vehicle pages, giving users an additional pathway to browse products.</li>
                    <li><strong>4.</strong> Code tables &amp; charts using HTML/SCSS that show product availability for certain vehicle types. </li>
                    <li><strong>5.</strong> Code pages that were added and  wireframed during the development phase of the project using PHP, HTML, and SCSS code.</li>
                    <li><strong>6.</strong> Perform frequent usability and quality assurance testing to ensure a user-friendly experience free of bugs and fatal errors.</li>
                    <li><strong>7.</strong> Code solutions to usability issues uncovered during usability testing.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="project-section project-section--first">
        <div class="sw">
          <div class="content">
            <div className="body">
              <h2>Wireframing and Prototyping</h2>
              {/* <p>I began this project by creating an 11 page low fidelity wireframe of the mini site using content created by a team member and approved by the client. Per the client's request, I branded the site as an Arctic Wolf site while using RVWSS fonts. I laid out the provided content based on my experience working on previous website projects and best practices of modern web design. The product pages would all follow the same outline, so I wireframed them to be identical to feel familiar to users who visited multiple product pages, and to reduce time spent on development by ensuring code could be reused during the development process.</p> */}
              <div className="images">
                <div className="img-container img-container--three">
                  <img src={pwireframe} alt="Puente Homepage Design" />
                </div>
                <div className="img-container img-container--three">
                  <img src={pcwireframe} alt="Puente Volunteer Page Design" />
                </div>
                <div className="img-container img-container--three">
                  <img src={ppwireframe} alt="Puente News Page Design" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <ResumeCTA />


    </Layout>
  )
}

export default ASC